import * as React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Collapsible from "../components/collapsible";
import Cta from "../components/cta";

const LodgingPage = () => (
  <Layout backgroundColor="#FBFBEF">
    <Seo title="Lodging" />

    <h1>Places to Stay</h1>

    <h2>Neighborhoods</h2>
    <p>
      We have not reserved a block of hotel rooms, so you are free to stay
      wherever you are most comfortable. For convenience to the wedding sites,
      you might prefer to book in SF’s northwestern neighborhoods, though you’ll
      find many more options downtown. We have made a few lodging
      recommendations below.
    </p>

    <p>
      Due to the uncertainties of the Delta variant we urge you to choose
      lodging with free cancellation and to book flexible travel options.
    </p>

    <p>
      The ceremony and reception will take place in the Marina District on the
      north side of the city.
    </p>

    <p>
      The welcome party will take place in the Outer Sunset neighborhood, near
      the ocean.
    </p>

    <p>
      The farewell brunch will take place in Golden Gate Park which is between
      the Sunset and the Richmond neighborhoods, in the western part of the
      city.
    </p>

    <Collapsible title="The Sunset" className="neighborhood">
      <p>
        We love our neighborhood and hope you will have time to explore it! Our
        favorite description is that it’s like an off-season beach town all year
        round, with a mix of Asian immigrants and surfers. This{" "}
        <a href="https://www.sfchronicle.com/projects/2021/outer-sunset-food-best-day-ever/">
          recent article
        </a>{" "}
        will give you some ideas of places to visit.
      </p>

      <ul>
        <h4>Places to stay:</h4>
        <li>
          Mirage Inn & Suites:
          <br /> The common areas are rather dingy, but the rooms are clean and
          comfortable and the rates are very reasonable. It’s close to the
          beach, the zoo, and one of the best garden centers in the city (Sloat
          Garden Center).{" "}
        </li>
      </ul>

      <ul>
        <h4>Things to do:</h4>
        <li>de Young Museum</li>
        <li>Walk on Ocean Beach</li>
        <li>Giant Camera and hike at Lands End</li>
        <li>Sutro Baths</li>
        <li>Golden Gate Park</li>
        <li>Japanese Tea Garden</li>
        <li>SF Botanical Garden </li>
        <li>Outer Sunset Farmers Market and Mercantile on Sunday</li>
      </ul>

      <ul>
        <h4>Coffee:</h4>
        <li>Trouble</li>
        <li>Andytown</li>
        <li>Avenues</li>
      </ul>

      <ul>
        <h4>Restaurants:</h4>
        <li>Outerlands (breakfast and lunch, Monica's favorite resaturant in the city)</li>
        <li>Palm City (Micah's favorite restaurant in the city)</li>
        <li>Damn Fine pizza</li>
        <li>Hook Fish (highly recommend)</li>
        <li>Sunset Reservoir Brewery</li>
        <li>Tartine Inner Sunset</li>
        <li>Arizmendi Bakery</li>
        <li>Devil’s Teeth Bakery (best breakfast sandwiches in the city)</li>
        <li>San Tung (famous for its Chinese chicken wings)</li>
      </ul>

      <ul>
        <h4>Bars:</h4>
        <li>Woods Outbound</li>
        <li>7 Stills</li>
        <li>White Cap</li>
      </ul>
    </Collapsible>

    <Collapsible
      title="The Richmond"
      className="neighborhood"
      id="the-richmond"
    >
      <p>
        The Richmond is our northern, also western neighborhood, with a similar
        relaxed vibe.{" "}
      </p>

      <ul>
        <h4>Things to do:</h4>
        <li>Balboa Theater</li>
        <li>Legion of Honor Museum</li>
        <li>California Academy of Sciences (amazing museum, great for kids)</li>
        <li>The Presidio</li>
        <li>Golden Gate Park</li>
        <li>Sutro Baths</li>
        <li>Lands End Trail</li>
      </ul>

      <ul>
        <h4>Restaurants and Cafés:</h4>
        <li>Arsicault Bakery (best croissant in SF)</li>
        <li>Jane the Bakery at Toy Boat</li>
        <li>Burma Superstar</li>
        <li>Hummus Bodega</li>
        <li>Pearl 6101</li>
        <li>Cinderella Bakery & Café</li>
      </ul>
    </Collapsible>

    <Collapsible title="The Marina" className="neighborhood" id="the-marina">
      <p>
        The Marina skews a little younger, with a lively night life scene. The
        main drag is along Chestnut Street.
      </p>

      <ul>
        <h4>Place to stay:</h4>
        <li>
          HI San Francisco Fisherman's Wharf Hostel: <br />
          This must be one of the most scenically-sited hostels anywhere, and
          it’s as close to our ceremony and reception as you can get, within
          Fort Mason.
        </li>
      </ul>

      <ul>
        <h4>Things to do:</h4>
        <li>The Palace of Fine Arts</li>
        <li>Crissy Field</li>
        <li>The Presidio</li>
        <li>Lands End Trail</li>
      </ul>

      <ul>
        <h4>Bars:</h4>
        <li>The Interval at Long Now (great cocktail bar in Fort Mason)</li>
      </ul>
    </Collapsible>

    <Collapsible title="Hayes Valley" id="hayes-valley">
      <p>Cute area for boutique shopping, with several major chains.</p>

      <ul>
        <h4>Restaurants and Cafés:</h4>
        <li>Salt & Straw ice cream</li>
        <li>Souvla (good fast casual chain of Mediterranean food)</li>
        <li>Zuni Café (an SF classic)</li>
      </ul>

      <ul>
        <h4>Bars:</h4>
        <li>Smuggler's Cove (tiki bar)</li>
        <li>Fig & Thistle (woman-owned wine bar)</li>
      </ul>
    </Collapsible>

    <Collapsible title="The Mission" id="the-mission">
      <p>
        A traditionally Latin American neighborhood that is home to many great
        shops and restaurants. It is one of the most popular places to visit.
        The main drag is along Valencia Street.{" "}
      </p>

      <ul>
        <h4>Things to do:</h4>
        <li>Mission Dolores Park</li>
        <li>Walk and shop along Valencia Street</li>
        <li>
          Also check out Noe Valley, its western neighborhood. The Main drag is
          along 24th Street.
        </li>
      </ul>

      <ul>
        <h4>Coffee:</h4>
        <li>Four Barrel Coffee</li>
        <li>Linea Caffe</li>
      </ul>

      <ul>
        <h4>Restaurants and Cafés:</h4>
        <li>Tartine Bakery</li>
        <li>Bi-Rite Creamery (ice cream by the park)</li>
        <li>Liholiho Yacht Club</li>
        <li>Souvla (good fast casual chain of Mediterranean food)</li>
        <li>Tacolicious</li>
        <li>Gracias Madre (vegan Mexican food)</li>
        <li>Loló</li>
        <li>El Techo</li>
        <li>El Farolito (best spot for Mission Style burritos, cash only!)</li>
        <li>Dandelion Chocolate</li>
      </ul>

      <ul>
        <h4>Bars:</h4>
        <li>The Beehive</li>
        <li>Royal Cuckoo Market (tiny, charming)</li>
      </ul>
    </Collapsible>

    <Collapsible title="Downtown" id="downtown">
      <p>
        You might consider Alcatraz, Cable Cars, Fisherman’s Wharf, Ghirardelli
        Square, Lombard Street, and the Painted Ladies as must sees, but they
        can be fairly crowded. You can take a really fun walking loop downtown
        and hit some other major sites!
      </p>

      <ul>
        <h4>Things to do:</h4>
        <li>Ferry Building</li>
        <li>Coit Tower</li>
        <li>North Beach (Tony’s Pizza Napoletana)</li>
        <li>City Lights booksellers</li>
        <li>Chinatown (dim sum!)</li>
        <li>Mikkeller Bar (incredible beer)</li>
        <li>Exploratorium (fun for kids!)</li>
        <li>SFMOMA</li>
        <li>Salesforce Park (SF’s elevated park) </li>
      </ul>
    </Collapsible>

    <Cta url="/things-to-do" text="Check out things to do" />
  </Layout>
);

export default LodgingPage;
