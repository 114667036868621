import * as React from "react"
import styled from "styled-components"


const CollapsibleContainer = styled.section`

    h3{
        cursor:pointer;
        margin:0;
        padding:0.5em 0;
        /* letter-spacing: -0.02em; */
        font-family: "Pitch Sans";
        text-transform:uppercase;
        font-size:1rem;
        border-bottom: 1px solid black;
        margin-bottom:12px;
        // position:relative;
        // z-index:1;
        // background:red;
        display:grid;
        grid-template-columns:1fr 1fr;

        .toggle{
            background:yellow
            position:absolute;
            right:0.2em;
            text-align:right;

        }
    }

    .content{
        height:0;
        overflow:hidden;


        &.visible{
            height:auto;
            padding-bottom:72px;
        }
    }
`



export default function Collapsible({title,text,children}){
    const [isOpen , setIsOpen] = React.useState(false);
    return(
        <CollapsibleContainer className="collapsible neighborhood">
            <h3 onClick={()=> setIsOpen(!isOpen)}  onKeyDown={()=> setIsOpen(!isOpen)} className="toggle">
                {title} 
                
                <span className="toggle">
                    {isOpen ? "-" : "+"}
                </span>
            </h3>
            <div className={isOpen ? "content visible" : "content"}>
                {children}
            </div>

        </CollapsibleContainer>
    )

}